<template>
      <v-card>
          <v-card-title>
            <span class="headline">{{sku}}</span>
          </v-card-title>
          <v-card-text>
  <v-data-table
                :headers="headers"
                :items="items"
                hide-default-footer
                class="elevation-1 mt-2"
              >
                <template v-slot:top>
                  <v-toolbar flat color="blue-grey lighten-2 ">
                    <v-toolbar-title>En route container</v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:no-data> none </template>
                <template v-slot:[`item.num`]="{ item }">
                  {{ item.num }}
                </template>
                <template v-slot:[`item.shippingRate`]="{ item }">
                  ${{ item.order.shippingRate }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <OrderStatusIcon :state="item.order.state" />
                </template>
              </v-data-table>
          </v-card-text>

        </v-card>

</template>

<script>
export default {
  props: {
    mongoId: { type: String },
    sku: { type: String },
  },
  data() {
    return {
      items: [],
      headers: [
        { text: 'Num', value: 'order.num' },
        { text: 'Case', value: 'numCase' },
        { text: 'Ship Date', value: 'order.shipDate', sortable: false },
        { text: 'Rcv Date', value: 'order.receivedDate', sortable: false }],
    };
  },
  created() {
    this.fetchEnrouteOrder();
  },
  methods: {
    fetchEnrouteOrder() {
      this.items = [];
      this.$http
        .get(`/enroute/${this.mongoId}`)
        .then((response) => {
          this.items = response.data.docs;
        });
    },
  },
  watch: {
    mongoId(val, oldVal) {
      this.fetchEnrouteOrder();
    },
  },
};
</script>
