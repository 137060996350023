<template>
  <div>
    <v-spacer />
    <v-card>
      <v-card-title>
        Search
        <v-switch v-model="showImg" :label="`Image`"></v-switch> &nbsp;
        <v-switch v-model="pricePoint" :label="`Price Point`"></v-switch>
        <v-spacer />
        <ProductSearch @query="query" />
      </v-card-title>
    </v-card>
    <v-card>
      <v-card-title>Profile:
        <strong class="blue--text ml-2">{{profileName}}</strong></v-card-title>
    </v-card>
    <div class="align-center">{{ lastSearch }}</div>
    <div v-if="zeroProductReturned">{{ errorMessage }}</div>
    <v-container>
      <div class="mb-5">
        <v-card v-for="(item , index) in items" :key="item.id" class="mb-10">
          <v-row class="mt-1">
            <v-col cols="4" v-if="showImg">
              <v-img
                :src="computeImageSrc(item.image_nm)"
                max-height="250"
                contain
                @error="imageErrorHandler"
              />
            </v-col>
            <v-col class="ml-4">
              <v-row
                >Item #:
                <span class="font-weight-bold">{{ item.prod }}</span>
                <v-icon color="orange" class="ml-3"
                v-if="profileName"
                :id="`addToCartBtn${index}`"
                @click="addToCartDialog(item,index)">mdi-plus-circle</v-icon>

                </v-row
              >
              <v-row
                >Desc:
                <span class="font-weight-bold">{{ item.descrip }}</span></v-row
              >
              <v-row
                >In Stock:
                <span class="font-weight-bold">{{ item.in_stock_cs }}</span>
                cases</v-row
              >
              <v-row
                >S/O Qty:
                <span class="font-weight-bold">{{ item.on_order_cs }}</span>
                cases</v-row
              >
              <v-row
                >Available:
                <span
                  class="font-weight-bold"
                  :class="{
                    'red--text': item.avail_cs <= 0,
                    'blue--text': item.avail_cs > 0,
                  }"
                  >{{ item.avail_cs }}</span
                >
                cases
              </v-row>
              <v-row
                >En-route:
                <span>{{ item.enRoute }}</span
                >
                cases
                <v-icon class="ml-3" v-if="item.enRoute > 0"
                  @click="showEnRouteInfo(item)">mdi-information</v-icon>
              </v-row>
              <v-row
                >P/O Qty:
                <span class="font-weight-bold">{{
                  item.po_qty / item.pc_case
                }}</span>
                cases</v-row
              >
              <!-- <v-row v-if="!hasProfile" -->
              <v-row
                >Price: $
                <span v-if="pricePoint">
                  <span class="font-weight-bold">{{ item.whole_prs }}</span> /
                <span class="font-weight-bold">{{ item.whole_prs2 }}</span> /
                <span class="font-weight-bold">{{ item.whole_prs3 }}</span>
                </span>
                <span v-else>
                  <span class="font-weight-bold">{{ matchPricePoint(item) }}</span>
                </span>
              </v-row>
              <v-row
                >Vol / Wght:
                <span class="font-weight-bold">{{ item.volumn }} ft<sup>3</sup> </span> /
                <span class="font-weight-bold">{{ item.weight }} lb </span>
              </v-row>
              <!-- <v-row v-else
                >Price: $
                <span class="font-weight-bold">{{ getPrice(item) }}</span> /
              </v-row> -->
            </v-col>
          </v-row>
          <!-- <v-row>
              <v-btn color="green" rounded
              @click="addToCartDialog(item)"
              class="mr-4 mb-n6"
              style="position:absolute; bottom:10;right:0;"> + </v-btn>
          </v-row> -->
        </v-card>
      </div>
    </v-container>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add to Cart</span>
          </v-card-title>
          <v-card-text>
              <div class="font-weight-bold mb-4 mt-2 ml-4 ">
              <v-row class="">{{this.editFields.sku}}</v-row>
              <v-row>{{this.editFields.descrip}}</v-row>
              <v-row>{{this.editFields.pc_case}} {{this.editFields.unit_nm}} / case
              </v-row>
              <v-row><v-col>{{this.editFields.volumn}} ft<sup>3</sup> -
                {{this.editFields.weight}} lb  / case</v-col></v-row>
              <v-row> ${{pricePerCase}} / case</v-row>
              <!-- <v-row> {{this.editFields.volumn}} ft<sup>3</sup>/ case</v-row>
              <v-row> {{this.editFields.weight}} lb/ case</v-row> -->
              </div>

            <v-form v-model="validDialog" @submit.prevent="addToCart()">

              <v-row justify="center" align="center">
                <v-col cols="6">
                  <v-text-field
                    v-model.number="editFields.price"
                    label="Price"
                    name="price"
                    prefix="$"
                    :rules="rule.positiveFloat"
                    type="number"
                    outlined
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="editFields.qty"
                    label="Cases"
                    name="qty"
                    type="number"
                    step="1"
                    :rules="rule.positiveInteger"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row class="font-weight-bold mt-n3">
              <v-col> Weight: {{editFields.weight*editFields.qty}} lb</v-col>
              </v-row>
              <v-row class="font-weight-bold mt-n3">
                <v-col>Volumn: {{editFields.volumn*editFields.qty}} ft<sup>3</sup></v-col>
              </v-row>
              <v-row class="font-weight-bold mt-n3">
                <v-col>Subtotal: $ {{subtotal}}</v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!validDialog"
              @click="addToCart"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showEnRouteDialog" max-width="500px">
        <EnRouteInfo :mongoId="enRouteMongoId" :sku="enRouteSKU" />
      </v-dialog>
  </div>
</template>
<style>
.align-center {
  text-align: center;
}
span {
  margin-left: 5px;
  margin-right: 5px;
}
</style>

<script>
import bus from '@/eventBus';
import ProductSearch from '@/components/ProductSearch.vue';
import rule from '@/library/rule';
import EnRouteInfo from '@/components/EnRouteInfo.vue';

export default {
  components: { ProductSearch, EnRouteInfo },
  data() {
    return {
      rule,
      pricePoint: false,
      showImg: true,
      enRouteSKU: '',
      showEnRouteDialog: false,
      enRouteMongoId: undefined,
      zeroProductReturned: false,
      lastSearch: '',
      errorMessage: 'No item found',
      items: [],
      validDialog: true,
      dialog: false,
      editFields: {
        descrip: '',
        sku: '',
        price: 0,
        qty: 1,
        pc_case: 0,
        unit_nm: '',
        image_nm: '',
      },
      priceType: 1,
    };
  },
  computed: {
    profileName() {
      const c = localStorage.getItem('customer');
      if (c) {
        const customer = JSON.parse(c);
        return `${customer.cus_nm} (${customer.priceType})`;
      }
      return '';
    },
    pricePerCase() {
      const t = this.editFields;
      return (t.price * t.pc_case).toFixed(2);
    },
    subtotal() {
      const t = this.editFields;
      const total = t.price * t.qty * t.pc_case;
      return total.toFixed(2);
    },
    hasProfile() {
      const c = localStorage.getItem('customer');
      return !!c;
    },

  },
  created() {
    document.addEventListener('keypress', this.scannerListener);
    this.showImg = localStorage.getItem('showImg') === '1';
    this.pricePoint = localStorage.getItem('pricePoint') === '1';
    const c = localStorage.getItem('customer');
    if (c) {
      this.priceType = JSON.parse(c).priceType;
    }
    // this.query('639ABCD');
  },
  watch: {
    showImg(val) {
      const v = val ? 1 : 0;
      localStorage.setItem('showImg', v);
    },
    pricePoint(val) {
      const v = val ? 1 : 0;
      localStorage.setItem('pricePoint', v);
    },
  },
  methods: {
    matchPricePoint(item) {
      if (this.priceType === 2) {
        return item.whole_prs2;
      } if (this.priceType === 3) {
        return item.whole_prs3;
      }
      return item.whole_prs;
    },
    showEnRouteInfo(info) {
      this.enRouteSKU = info.prod;
      this.enRouteMongoId = info.mongoId;
      this.showEnRouteDialog = true;
    },
    getPrice(item) {
      if (this.priceType === 3) return item.whole_prs3;
      if (this.priceType === 2) return item.whole_prs2;
      return item.whole_prs;
    },
    query(e) {
      this.lastSearch = e;
      this.$http
        .get(`/inventory/${e}`)
        .then((response) => {
          this.items = [];

          const { data } = response.data;
          data.forEach((item) => {
            this.addItemData(item);
          });
          this.zeroProductReturned = this.items.length === 0;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    imageErrorHandler(url) {
      // this.images[0] = '/img/imgnotavail.jpg';
      // this.$forceUpdate();

    },
    addItemData(data) {
      const d = data;
      d.in_stock_cs = (data.in_stock / data.pc_case).toFixed(2);
      d.on_order_cs = (data.so_qty / data.pc_case).toFixed(2);
      d.avail_cs = ((data.in_stock - data.so_qty) / data.pc_case).toFixed(2);
      d.avail_unit = (data.in_stock - data.so_qty).toFixed(2);
      this.items.splice(0, 0, data);
    },
    computeImageSrc(src) {
      if (!!src && src.length >= 0) {
        return `api/image/thumbnail/${src}`;
      }
      return 'img/imgnotavail.jpg';
    },
    addToCartDialog(item, index) {
      this.editFields.descrip = item.descrip;
      this.editFields.sku = item.prod;
      this.editFields.price = this.getPrice(item);
      this.editFields.pc_case = item.pc_case.toFixed(0);
      this.editFields.unit_nm = item.unit_nm;
      this.editFields.image_nm = item.image_nm;
      this.editFields.volumn = item.volumn;
      this.editFields.weight = item.weight;
      this.editFields.qty = 1;
      this.dialog = true;
      // prevent pressing enter trigging this event again
      const e = document.getElementById(`addToCartBtn${index}`);
      if (e) e.blur();
    },
    addToCart() {
      this.dialog = false;
      let cart = localStorage.getItem('cart');
      cart = (!cart) ? [] : JSON.parse(cart);

      cart.push(this.editFields);
      localStorage.setItem('cart', JSON.stringify(cart));
    },
  },
};
</script>
