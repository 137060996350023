<template>
  <v-toolbar dense>
    <v-text-field
      hide-details
      v-model.trim="search"
      :rules="rule.notEmpty"
      single-line
      placeholder="SKU / UPC"
      v-on:keyup.enter="query"
      ref="input"
      @focus="searchboxfocus=true" @blur="searchboxfocus=false"
    ></v-text-field>
    <v-btn icon @click="query" >
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
// import bus from '@/eventBus';
import rule from '@/library/rule';

export default {
  data() {
    return {
      isLoading: false,
      selected: null,
      search: '', // match box value
      rule,
      searchboxfocus: false,
      barcode: {
        scannerTimeout: 1450, // barcode scanner result timeout before reset
        capturedBarcode: '',
        reading: false,
      },
    };
  },
  computed: {},
  watch: {

  },
  created() {
    document.addEventListener('keypress', this.scannerListener);
    // this.search = '707';
    // this.query();
  },
  methods: {
    query() {
      if (this.search.length <= 1) {
        // bus.$emit('error', 'length too short');
        return;
      }
      this.$emit('query', this.search);
      this.$refs.input.blur();
      this.search = '';
    },

    scannerListener(e) {
      if (this.searchboxfocus) return;
      if (e.keyCode === 13) { // query when "enter" event is triggered
        this.search = this.barcode.capturedBarcode;
        this.barcode.capturedBarcode = '';
        this.query();
      } else {
        if (!this.barcode.capturedBarcode) this.barcode.capturedBarcode = '';
        this.barcode.capturedBarcode += e.key;
      }

      if (!this.barcode.reading) {
        this.barcode.reading = true;
        // auto reset after a certain amount of time
        setTimeout(() => {
          this.barcode.capturedBarcode = '';
          this.barcode.reading = false;
        }, this.barcode.scannerTimeout);
      }
    },
  },
};
</script>
